@import "/src/style/colors.module.scss";

.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .header {
    position: sticky;
    top: 0px;
    z-index: 1000;
    height: 70px;
    border-bottom: solid 1px black;
    background-color: $darkGreen;
    display: flex;
    justify-content: space-between;

    .logo {
      height: 100%;
      width: 100%;
      object-fit: contain;
      padding: 20px;
    }

    .notifications {
      display: flex;
      padding: 15px;
    }

    .menu {
      display: flex;
      height: 100%;
      align-items: center;
      cursor: pointer;

      .border {
        height: 100%;
        border-left: 1px solid $lightGreen;
      }

      .name {
        color: white;
        margin-left: 20px;
      }

      .image {
        border-radius: 20px;
        border: 2px solid white;
        height: 40px;
        width: 40px;
        margin: 20px;
        object-fit: cover;
      }
    }
  }

  .content {
    display: flex;
    flex: auto;
    overflow: auto !important;
  }

  > .footer {
    position: sticky;
    z-index: 110;
    bottom: 0px;
    height: 80px;
    border-top: solid 1px $orange;
    display: flex;
    background: white;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
}

.hidelongtext {
  margin-left: 10px;
}
.titleheader {
  display: flex;
}
.ant-checkbox-inner {
  border-radius: 100% !important;
}

.landing-page {
  display: flex;
  flex-direction: column;
  flex: auto;
  min-height: 100vh;

  > .background {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 120px;
    padding-right: 120px;
    display: flex;
    flex-direction: column;
    flex: auto;
    justify-content: space-between;
  }
  .design_heading {
    display: flex;
    align-items: center;
    // padding-left: 300px;
    // padding-right: 120px;
    // justify-content: center;
    // align-items: center;
    justify-content: space-around;
    // gap: 100px;

    // gap: 280px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
    gap: 20px;
  }

  .separator {
    border: 1px solid $orange;
    height: 100px;
    margin: 30px;
  }

  .center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .subtitle {
    height: fit-content;
    font-size: 30px;
    color: white;
    font-style: italic;
  }

  // mobile layout
  &.mobile {
    .background {
      padding: 10px;
    }
    .center {
      flex-direction: column;
      img {
        width: 100%;
      }
      .subtitle {
        text-align: center;
        line-height: initial;
      }
    }
    .separator {
      height: 0px;
      width: 100px;
    }
    .footer {
      flex-direction: column-reverse;
      div {
        flex-direction: column;
        text-align: start !important;
        br {
          display: none;
        }
      }
    }
  }
}

.login-form {
  padding: 80px;

  &.mobile {
    padding: 20px;
  }

  .form-title {
    font-size: 20px;
    color: $orange;
    margin-bottom: 40px;
  }

  .login-form-forgot {
    font-size: 10px;
    display: flex;
    .login-form-forgot-url {
      color: $orange;
      text-decoration: underline;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .btn {
    height: 40px;
  }

  .logo {
    margin-top: 100px;
    float: right;
    height: 60px;
  }
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-form {
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0px !important;
  }

  .form-item {
    display: inline-block !important;
    width: 60%;
    padding: 5px !important;

    &.small {
      width: 40%;
    }
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.grecaptcha-badge {
  z-index: 100000;
  visibility: hidden;
}

.captcha-visible {
  .grecaptcha-badge {
    visibility: visible;
  }
}

.help-form {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-item {
    display: inline-block !important;
    width: 50%;
    padding: 10px !important;
  }

  .full {
    width: 100% !important;
  }
}

.registration-form {
  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .form-item {
    display: inline-block !important;
    width: 50%;
    padding: 10px !important;
  }

  .third {
    width: 33% !important;
  }

  .label {
    padding-left: 10px;
    font-size: 10px;
    color: gray;
  }

  .ant-radio-wrapper {
    color: gray;
  }

  &.mobile {
    padding: 0px;
    .form-item {
      padding: 5px;
    }
  }

  padding: 80px;

  &.no-padding {
    padding: 0px;
  }

  .form-title {
    margin: 20px;
    color: $orange;
  }

  .submit-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px;

    .btn {
      height: 40px;
    }

    .logo {
      height: 60px;
    }
  }
}

.ant-layout-sider-zero-width-trigger {
  box-shadow: 2px 2px 8px lightgrey;
  z-index: 10;
}

.side-menu-container {
  position: sticky;
  top: 70px;
  display: flex;
  height: calc(100vh - 150px);
  border: 1px solid lightgray;
  z-index: 100;
}

.side-menu {
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 100%;
  justify-content: space-between;

  .bottom {
    margin-top: 40px;
  }

  .progress-container {
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 20px;

    .text-container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .text {
        color: $lightGreen;
        font-size: 12px;
      }
      .perc {
        color: $lightGreen;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .image {
    border-radius: 110px;
    border: 2px solid $orange;
    margin: 30px;
    width: 110px;
    height: 110px;
    align-self: center;
    box-shadow: 6px 6px 8px lightgray;
    object-fit: cover;
  }

  .ant-menu-item {
    margin: 0px !important;
    padding: 0px !important;
    height: auto !important;
    color: unset !important;
  }

  .ant-menu-submenu-selected,
  .ant-menu-submenu-active,
  .ant-menu-submenu-arrow {
    color: unset !important;
  }

  .ant-menu-submenu {
    padding: 0px !important;

    color: unset !important;

    .ant-menu-sub.ant-menu-inline {
      background: none !important;
    }

    .ant-menu-item {
      padding-left: 50px !important;
    }
  }

  .ant-menu-submenu-title {
    padding: 0px !important;
    padding-left: 30px !important;
    color: unset !important;
  }

  .ant-menu-item:after {
    border: none !important;
  }

  .button {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    transition: width 0.2s, height 0.2s;
    &:hover {
      .circle {
        height: 30px;
        width: 15px;
      }
    }

    .text {
      margin-left: 30px;
    }
    .circle {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      background: orange;
      height: 20px;
      width: 10px;
    }
  }
}

.ant-btn[disabled]:hover {
  cursor: not-allowed;
  background: lightgray !important;
  border: none;
}

.ant-btn[disabled] {
  cursor: not-allowed;
  background: lightgray !important;
  border: none;
}

@media screen and (max-width: 900px) {
  .home {
    padding: 20px !important;
    .widget {
      grid-column-end: span 1 !important;
    }
  }
}

.loader {
  > [data-testid="overlay"] {
    opacity: 0.4;
  }
}

.widget.files-uploader {
  min-height: 400px;
  .ant-checkbox-wrapper {
    align-items: flex-end;
  }

  color: #000;
  > span {
    height: 100%;
  }
  &.full-page {
    width: 100%;
    height: unset;
    margin: 30px;

    .ant-upload.ant-upload-drag {
      cursor: default !important;
    }
  }
}

.white-spinner {
  .ant-spin-dot-item {
    background-color: white;
  }
}

.widget {
  grid-column-end: span 1; // extend to this number of columns
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: lightgray;
  color: white;
  padding: 30px;
  width: 100%;
  height: 320px;
  border-radius: 30px;

  .ant-upload {
    background: unset !important;
    padding: 0px !important;
    border: none !important;

    .files-preview-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      color: white;
      justify-content: center;
      margin-top: 20px;
      padding-left: 40px !important;
      padding-right: 40px !important;
      padding-bottom: 20px;
    }

    .ant-upload-drag-container {
      height: 100%;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border: 1px dashed white !important;
  }

  .btn-container {
    display: flex;
    align-items: center;

    .button {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;

      .button-icon {
        height: 40px;
        line-height: 36px;
        font-size: 28px;
      }
    }

    .button-text {
      margin-left: 10px;
      font-weight: bold;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    font-weight: bolder;
  }

  .subtitle {
    margin-top: 10px;
    font-size: 12px;
  }

  .content {
    font-size: 11px;
    display: flex;
    overflow: auto;
    flex: auto;
    flex-direction: column;
  }
}

.folderImage {
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

@keyframes upDown {
  from {
    margin-top: 0px;
  }
  to {
    margin-top: 20px;
  }
}

.scroll-arrow {
  animation: upDown 0.6s cubic-bezier(0.4, 0, 1, 1) 0s infinite alternate;
}

.home {
  display: grid;
  width: 100%;
  padding: 20px;
  padding-left: 100px;
  padding-right: 100px;
  gap: 20px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(220px, 0.2fr)
  ); //minmax col width
  justify-content: center;
  grid-auto-rows: 320px;
  grid-auto-flow: row dense; // fill holes with smaller items (the order changes)
}

.files-grid-layout {
  overflow: scroll;
  display: grid;
  width: 100%;
  padding: 20px;
  padding-left: 80px;
  padding-right: 80px;
  gap: 10px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(220px, 0.2fr)
  ); //minmax col width
  justify-content: center;
  grid-auto-rows: 220px;
  grid-auto-flow: row dense; // fill holes with smaller items (the order changes)
}

.family-node {
  background: $orange;
  border: 1px solid $orange;
  border-radius: 100%;
}

.person-edit-card {
  .footer {
    bottom: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.matches-table {
  .ant-table-container {
    font-size: 11px;
  }

  .ant-table-cell {
    min-width: 45px;
  }

  table {
    width: auto !important;
    min-width: unset !important;
    border-collapse: separate !important;
    border-spacing: 0px 3px !important;
  }
}

.matches-row {
  border: 1px solid $darkGreen;
  background-color: $lightGreenAlpha;
  border-radius: 10px;
  > :first-child {
    border-radius: 10px 0 0 10px;
    -moz-border-radius: 10px 0 0 10px;
  }

  > :last-child {
    border-radius: 0 10px 10px 0;
    -moz-border-radius: 0 10px 10px 0;
  }
}

.ant-modal-body {
  max-height: 85vh;
  overflow: auto;
}

.ant-modal-footer {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
}

.ant-modal-content {
  max-height: 95vh;
  overflow: hidden;
}

.wizard {
  color: white !important;
  .ant-modal {
    color: white;
  }
  .ant-modal-close-x {
    color: white;
  }
}

circle {
  cursor: pointer !important;
}

.person-node {
  background: transparent;
  border: 1px solid $orange;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 100% !important;

  &.dead {
    border: 1px solid darkgray;
  }

  &.focus {
    border: 2px solid red;
  }

  &.focus.dead {
    border: 2px solid darkgray;
  }

  .plus {
    cursor: pointer;
    width: 17px;
    height: 17px;
    position: absolute;
    bottom: 0px;
    left: 100%;
    border: 1px solid $orange;
    border-radius: 100%;
    font-size: 17px;
    color: $orange;
    line-height: 14px;
  }

  .edit {
    cursor: pointer;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 0px;
    left: 100%;
    border: 1px solid $orange;
    border-radius: 100%;
    font-size: 17px;
    color: $orange;
    line-height: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .expand {
    cursor: pointer;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 43px;
    right: -10px;
    z-index: -2;
    font-size: 20px;
    color: $orange;
    line-height: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sync {
    cursor: pointer;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 90%;
    font-size: 17px;
    line-height: 11px;
  }

  .label {
    background: white;
    opacity: 1; //0.8;
    text-align: center;
    position: relative;
    top: 88%;
    cursor: auto;
  }
}

$switch-height: 30px;

.ant-switch {
  width: 50%;
  height: $switch-height !important;
}

.ant-switch-handle {
  height: calc(#{$switch-height} - 4px) !important;
  width: calc(#{$switch-height} - 4px) !important;
  &::before {
    border-radius: 100% !important;
  }
}

.ant-switch-checked {
  background-color: $orange !important;
  & .ant-switch-handle {
    left: calc(100% - calc(#{$switch-height} - 4px) - 2px) !important;
  }
}

.search-bar {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.edit-relations {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-document-btn {
  margin: 10px;
  border-radius: 10px;
  color: rgb(31, 52, 47);
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.safe-lock-switch {
  .ant-switch-handle {
    height: 36px !important;
    width: 36px !important;
  }

  &.ant-switch-checked {
    & .ant-switch-handle {
      left: calc(100% - 36px - 2px) !important;
    }
  }

  height: 40px !important;
  width: 220px !important;
  background-color: white !important;
  .ant-switch-inner {
    color: $lightGreen !important;
  }
  &.ant-switch-disabled {
    cursor: auto !important;
    opacity: 1 !important;
  }
}

.controls-button {
  background: $limeGreen;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-bottom: 5px;

  &:hover {
    background: $lightOrange;
  }
}

.profile {
  width: 100%;
  height: 100%;
  overflow: scroll;
  color: $lightGreen;
  padding: 20px 60px 0px;
}

.ant-upload-drag {
  .drop-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    font-size: 20px;
    font-weight: bold;
    color: $lightGreen;
  }
  .dragger-content {
    text-align: start;
    &.blur {
      filter: blur(2px) opacity(0.3);
    }
  }
}

.files-content,
.folder-content {
  > span {
    width: 100%;
    height: 100%;
  }
}

.folder-content {
  .ant-upload.ant-upload-drag {
    .ant-upload.ant-upload-btn {
      .ant-upload-drag-container {
        .dragger-content {
          text-align: center;
        }
      }
    }
  }
}
.ant-upload.ant-upload-drag {
  .ant-upload.ant-upload-btn {
    display: flex;
    height: 100%;
    width: 100%;
    .ant-upload-drag-container {
      width: 100%;
      height: 100%;
      .dragger-content {
        height: 100%;
      }
    }
  }
}
.action-buttons-container {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  .action-button {
    display: flex;
    align-items: center;
    border: 1px solid $purple;
    background-color: $purpleAlpha;
    gap: 5px;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    justify-content: center;
    cursor: pointer;
  }
}
.files {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: $lightGreen;
  padding: 10px 40px 10px;

  .ant-btn {
    background-color: $purpleAlpha;
    border-color: $purple !important;
    color: $purple !important;
    &.toggled {
      background-color: $purpleAlpha !important;
      color: $purple !important;
    }
    &:hover {
      background-color: $purpleAlpha !important;
      border-color: $purple !important;
      color: $purple !important;
    }
  }

  .back-button {
    color: white;
    cursor: pointer;
    padding: 2px 8px 2px 8px;
    width: fit-content;
    background: $darkGreenAlpha;
    border-radius: 100px;
  }

  .tag-chip {
    margin-left: 1px;
    color: $darkGreen;
    text-align: center;
    width: fit-content;
    font-size: 10px;
    border-radius: 3px;
    padding: 2px;
    padding-left: 8px;
    padding-right: 2px;
  }

  .safe-logo {
    width: 100px;
    height: 100px;
    margin-left: 25px;
    margin-right: 25px;
    padding: 10px;
  }
  .fileitems {
    display: flex;
  }
  .title {
    padding: 20px;
    padding-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 100%;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 2px solid white;
    background-color: $lightGreen;
  }
  .feet {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;

    .foot {
      background: $lightGreen;
      width: 50px;
      height: 15px;
    }
  }
  .files-section-height {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    position: relative;
    // border: 1px solid $lightGreen;
    border-radius: 10px;

    .border {
      border: 3px solid $lightGreen;
      border-radius: 5px;
      height: 100%;
    }
    height: 500px;
  }
  .searchbutton {
    border: 1px solid $purple;
    background-color: $purpleAlpha;
    border-radius: 71px;
    border-color: $purple;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    color: $purple;
  }
  .files-section {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: auto;
    //position: relative;
    // border: 1px solid $lightGreen;
    border-radius: 10px;
    height: 100%;
    .border {
      border: 3px solid $lightGreen;
      border-radius: 5px;
      height: 100%;
    }

    &.open {
      // height: calc(100% - 150px);
    }
  }

  .line {
    border: 1px solid $lightGreen;
    margin: 0px 5px;
  }

  .drawer-container {
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(170px);
    scroll-snap-type: y mandatory;
    display: grid;
    height: fit-content;
    overflow: auto;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    place-items: baseline;
  }

  .safe-drawer {
    cursor: pointer;
    scroll-snap-align: start;
    grid-column-end: span 3;
    height: 100%;
    width: 100%;
    padding: 15px;

    display: flex;
    .body {
      display: flex;
      flex: auto;
      border: 2px solid #4f8376;
      border-radius: 5px;
      align-items: center;
    }
  }

  .drawer {
    cursor: pointer;
    scroll-snap-align: start;
    grid-column-end: span 1;
    height: 100%;
    width: 100%;
    padding: 15px;
    text-overflow: ellipsis;
    overflow: hidden;

    .back-container {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      height: 0px;

      .back {
        width: 100%;
        height: 100%;
        border: 2px solid $lightGreen;
        border-bottom: unset;
      }
    }
    .body {
      // border: 2px solid $lightGreen;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100px;
      overflow: hidden;
      justify-content: space-evenly;

      &:hover {
        background: rgba(93, 185, 162, 0.2);
      }

      .handle {
        height: 16px;
        width: 16px;
        border-radius: 100%;
        align-self: center;
        border: 4px solid $lightGreen;
      }

      .label {
        border: 1px solid $lightGreen;
        margin: 0px 15px;
        background: $lightGreenAlpha;
        height: 50px;
        text-align: center;
        padding: 5px;
        text-transform: capitalize;
      }

      .drawer-content-header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        .center {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .side {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    &.cell {
      border-bottom: unset; //1px solid;
      border-right: unset; // 1px solid;
      max-width: 200px;

      .tab {
        position: relative;
        top: -15px;
        left: -1px;
        display: flex;
        height: 20px;
        width: 100px;

        .rect {
          width: 80px;
          height: 100%;
          background: white;
          border-top-left-radius: 10px;
          border-top-right-radius: 12px;
          // border-left: 1px solid;
        }
        .corner {
          position: relative;
          right: 5px;
          height: 15px;
          border-top-left-radius: 7px;
          width: 15px;
          background: linear-gradient(225deg, transparent 12px, white 12px);
        }
      }

      .white-folder {
        position: relative;
        top: 12px;
        border: 1px solid;
        border-radius: 0px 10px 10px;
        height: 100%;
        background: darkmagenta;
        color: white;
        font-weight: 500;

        .tab {
          .rect {
            position: relative;
            top: 1px;
            background-color: darkmagenta;
          }
          .corner {
            position: relative;
            top: 1px;
          }
        }
      }

      .green-folder {
        z-index: -1;
        position: relative;
        top: -115px;
        border: 1px solid;
        border-radius: 0px 10px 10px;
        height: 100%;
        // background: darkmagenta;
        width: calc(100% - 3px);

        .tab {
          .rect {
            background-color: darkmagenta;
          }
          .corner {
            background: linear-gradient(
              225deg,
              transparent 12px,
              darkmagenta 12px
            );
          }
        }
      }

      @keyframes upDown {
        to {
          top: -118px;
        }
      }

      @keyframes whiteGreen {
        to {
          background: $lightGreen;
          color: white;
          border: none;
        }
      }

      @keyframes whiteGreenRect {
        to {
          background: $lightGreen;
          border: none;
          left: 1px;
          position: relative;
        }
      }

      @keyframes whiteGreenCorner {
        to {
          background: linear-gradient(
            225deg,
            transparent 12px,
            $lightGreen 12px
          );
        }
      }

      &:hover {
        .green-folder {
          animation: upDown 0.6s forwards;
        }
      }
    }

    &.open {
      width: 100%;

      cursor: default;
      height: fit-content !important;
      padding: unset;
      margin-top: 20px;

      &.safe {
        .body {
          border-width: 10px;
        }
      }

      .body {
        justify-content: flex-start;
        min-height: 100%;
        &:hover {
          background: unset;
        }

        .folder-header {
          display: flex;
          flex-direction: column;
        }

        .files-list {
          overflow: auto;
        }
      }

      height: calc(100% - 50px);
      .back-container {
        visibility: hidden;
      }
      .line {
        visibility: hidden;
      }
      .handle {
        margin: 5px;
      }
      .label {
        margin-top: 10px;
        width: fit-content;
        min-width: 200px;
        align-self: center;
        background: unset;
        margin-bottom: 20px;
      }
    }
  }
}

.help-button {
  width: fit-content;
  margin: 20px;
  height: 40px;
  padding: 10px 30px;
  border-radius: 1000px;
  color: white;
  background: $lightGreen;
  z-index: 10000;
  cursor: pointer;
}

.multiple-file-form {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 100%;
    //  border: 1px solid;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 2px solid white;
    background-color: $lightGreen;
  }
}

.blur {
  z-index: 2 !important;
  filter: blur(5px) opacity(0.5);
  text-align: center;
}

.tree {
  display: flex;
  flex: auto;
  ._loading_overlay_wrapper {
    display: flex;
    flex: auto;
    ._loading_overlay_overlay {
      z-index: 100000;
      opacity: 0.5;
    }
  }
  .canvas-container {
    width: 100%;
    height: 100%;
    position: relative;

    > svg {
      width: 100% !important;
      height: 100% !important;
      position: absolute;

      .circle-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
      }

      foreignObject {
        overflow: visible;
      }
    }
  }

  .tree-card-closed {
    transition: right 0.3s ease-in-out;
    right: 0px;
  }
  .tree-card-open {
    transition: right 0.3s ease-in-out;
    right: 450px;
  }

  .react-flow__node {
    cursor: default;
    padding: unset;
    width: unset;
    height: unset;
    border: unset;
    box-shadow: unset !important;
    background: transparent;
  }

  .react-flow__edge-blur {
    z-index: 2 !important;
    filter: blur(2px);
  }

  .react-flow__edge-path {
    stroke: $orange;
  }

  .depth-selector {
    position: absolute;
    z-index: 1000;
    margin: 20px;
    padding: 5px;
    background: white;
    box-shadow: 0px 0px 1px lightslategrey;
  }
}

.landing-page {
  .helpButton {
    background-color: $lightGreen !important;
    color: white !important;
  }
  .ant-btn:not(.ant-btn-primary):not(.helpButton) {
    background-color: transparent !important;
    border-color: white !important;
    color: white !important;
  }

  .ant-btn:not(.ant-btn-primary):hover {
    background-color: $lightGreen !important;
    border-color: $lightGreen !important;
    color: white !important;
  }
}

.ant-btn {
  padding: 4px 20px !important;
  background-color: transparent !important;
  border-color: $lightGreen !important;
  color: $lightGreen !important;
}

.ant-btn-circle {
  padding: 0px !important;
}

.ant-btn:hover {
  background-color: $lightGreen !important;
  border-color: $lightGreen !important;
  color: white !important;
}

.ant-btn-primary {
  background-color: $orange !important;
  border-color: $orange !important;
  color: white !important;
}

.ant-btn-primary:hover {
  background-color: orangered !important;
  border-color: orangered !important;
}

.round-button {
  color: #1f342f !important;
  border-radius: 150px !important;
  border-color: #1f342f !important;
  background: transparent !important;
  width: 200px;
  height: 25px !important;
  margin-top: 10px;
  padding: 0px !important;
}

.connection-card {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  margin-top: 15px;
  margin-bottom: 5px;

  .relationship {
    position: relative;
    background: $orange;
    font-size: 10px;
    width: 60px;
    text-align: center;
    border-radius: 100px;
  }
  .name {
    margin-left: -20px;
    padding: 3px;
    padding-left: 30px;
    width: 180px;
    background: $darkGreenAlpha;
    border-radius: 100px;
  }
}

.notification-item-container {
  background-color: white !important;

  &:hover {
    cursor: default !important;
  }
}

.notification-item-action-container {
  display: flex;
  gap: 5px;
}

.notification-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px lightgray;

  .notification-item-icon {
    width: 30px;
    height: 30px;
    border-radius: 999px;
    padding-right: 10px;
  }

  .notification-item-text {
    flex: 1;
    margin: 0;
    padding-right: 10px;
  }
}

.modal-footer-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.img-horizontal-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.feed-layout {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.feed-background {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inactive-chat-card {
  &:hover {
    background-color: rgba(61, 136, 186, 0.3);
  }
}

.chat-card {
  &:hover {
    background-color: rgba(61, 136, 186, 0.3);
  }

  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px;

  .pending-post {
    background-color: $orange;
    color: white;
    border-radius: 20px;
    height: 20px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
  }
}

.family-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  border-bottom: 1px solid $lightGreen;

  .selected {
    background-color: $lightGreen !important;
    color: white !important;
  }
}

.person-line {
  stroke: $lightGreen;
  stroke-width: 1;
  fill: none;
}

.person-view {
  flex: auto;
  display: flex;
  position: relative;
  overflow: auto !important;
  justify-content: flex-start;
  //align-items: center;
  flex-direction: column;
  padding-bottom: 20px;

  &.core > * {
    margin: auto;
  }

  &.ancestors {
    overflow: initial !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: flex-start;
    padding-top: 25px;
  }
  .container_tree {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    justify-content: flex-start;
    padding-top: 25px;
  }

  .line_parent {
    position: absolute;
    float: right;
  }

  .title {
    text-align: center;
    font-style: italic;
    color: $lightGreen;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .centerButton-layout {
    margin-top: 165px;
    margin-left: 60px;
  }
  .children-layout {
    margin-top: 100px;
  }

  .side-column {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.ancestors {
      justify-content: center;
    }
  }
  .side-column-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;

    margin-left: 10px;
  }
  .side-column-tree {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 150px;
  }
  .item-pg1 {
    margin-left: 400px;
  }
  .item-pg2 {
    margin-left: 400px;
    position: absolute;
    margin-top: 155px;
  }

  .scrollable-list {
    //overflow-y: auto;
    padding: 5px;
  }

  .central-layout-layoutTree {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .circle-and-children {
      position: absolute;
      margin-top: 85px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 200px !important;
      margin-top: 110px !important;
    }
  }
}
.central-layout {
  display: flex;
  flex-direction: row;

  &.ancestors {
    gap: 20px;
    width: 100%;
    > :first-child {
      margin: 0px 0px 0px auto;
    }
    > :last-child {
      margin: 0px auto 0px 0px;
    }
  }

  .circle-and-children {
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.central-layout1 {
  position: absolute;
  flex-direction: row;
  overflow: hidden;
  margin-left: 20px;
}
.side-column1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-layout {
  display: grid;
  // width: 100%;
  grid-template-columns: 162px 162px 162px 162px;
  grid-template-rows: 100%;
  column-gap: 60px;
  justify-items: center;
  align-items: center;
  //  position: relative;
  // justify-content: end;
  // margin-right: 50px;
}
.grid-layout-tree {
  display: flex;
  width: 100%;
  grid-template-columns: 162px 162px 162px 162px;
  grid-template-rows: 100%;
  column-gap: 40px;
  justify-items: center;
  align-items: right;
  position: relative;
  justify-content: end;
  margin-right: 50px;
}
.grid-layout_tree {
  display: grid;
  grid-template-columns: 170px 170px;
  grid-template-rows: 100%;

  justify-items: flex-end;
  align-items: flex-end;
  position: relative;
}

.grid-move-left {
  margin-right: -200px;
  margin-bottom: auto;
}

.person-card-grid-layout {
  display: grid;
  grid-template-columns: 100px 250px 100px;
  grid-template-rows: 50%;
  column-gap: 5px;
  justify-items: center;
  align-items: center;
  position: relative;
}

.person-circle {
  width: 120px;
  height: 120px;
  border: 8px solid $lightGreen;
  border-radius: 100%;
  cursor: pointer;
  .inner {
    border: 1px solid $neonGreen;
    border-radius: 100%;
    height: 100%;
    width: 100%;
  }
}

.person-button {
  direction: ltr;
  width: 200px;
  height: 70px;
  margin: 6px;
  border-radius: 30px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 15px;
  border: 1px solid $lightGreen;
  color: $lightGreen;
  background-color: white;

  font-style: italic;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
  }
  .text-line {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  cursor: pointer;
  &.active {
    background-color: $lightGreen;
    color: white;
  }
  box-shadow: 0px 1px 2px 0px grey;

  &:hover {
    box-shadow: 0px 2px 5px 0px grey;
    filter: brightness(1.1);
  }
}
.person-button_layout {
  direction: ltr;
  width: 170px;
  height: 60px;
  margin: 6px;
  border-radius: 30px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 15px;
  border: 1px solid $lightGreen;
  color: $lightGreen;
  background-color: white;

  font-style: italic;
  // text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.large {
    width: 200px;
  }
  &.mini {
    height: 50px;
  }
  &.main {
    width: 240px;
    height: 80px;
  }

  &.micro {
    height: 30px;
    width: 150px;
    padding: 5px;
    //select parent

    :first-child {
      :first-child {
        // initials
        width: 25px !important;
        height: 25px !important;
      }
    }
    :nth-child(2) {
      // name and surname
      padding: 0px 0px 0px 4px !important;
      text {
        font-size: 5px !important;
      }
    }
  }

  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
  }
  .text-line {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  cursor: pointer;
  &.active {
    background-color: $lightGreen;
    color: white;
  }
  box-shadow: 0px 1px 2px 0px grey;

  &:hover {
    box-shadow: 0px 2px 5px 0px grey;
    filter: brightness(1.1);
  }
}

.no-focus-input {
  flex: auto;
  &:focus-visible {
    outline: 0px;
  }
}
.groups-sidebar {
  width: 260px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px 30px;
  overflow: auto;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.feed-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
  max-width: 800px;
  width: 100%;
}

.post-creator {
  border: solid 1px #f0f0f0;
  border-radius: 40px;
  padding: 7px 20px 6px;
  background-color: #f0f0f0;
}

.chat-list {
  overflow: hidden auto;
  max-width: 500px;
  min-width: 315px;
  overflow-y: overlay;
}

.post-container {
  max-width: 500px;
  min-width: 200px;
  border-radius: 12px;
  padding: 10px 10px;
  position: relative;

  &.other {
    background-color: rgb(233, 233, 233);
    margin-right: 80px;
  }

  &.me {
    background-color: rgb(182, 215, 237);
    margin-left: 80px;

    .author-header {
      position: absolute;
      right: 5px;
      top: 4px;
      background-color: rgb(182, 215, 237);
      z-index: 1;
      padding: 6px;
      box-shadow: -4px 3px 4px #b6d7ed;
      border-bottom-left-radius: 20px;
      transition: opacity 0.2s ease-out;

      .author-meta-container {
        width: 0px;
      }
      .post-action-container {
        grid-gap: 0px;
        gap: 0px;
        padding-left: 0px;
      }
      opacity: 0;
    }

    &:hover {
      .author-header {
        opacity: 1;
      }
    }
  }

  .post-date {
    display: flex;
    flex-direction: row-reverse;
    color: rgb(112, 112, 112);
    font-weight: bold;
    font-size: 10px;
    opacity: 0.5;
  }
  .description-input {
    margin: 10px 0;
  }
  .arrow-left::after {
    content: " ";
    position: absolute;
    width: 0px;
    height: 0px;
    right: auto;
    left: -10px;
    top: auto;
    bottom: 0px;
    border-left: 13px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 12px solid rgb(233, 233, 233);
  }
  .arrow-right::after {
    content: " ";
    position: absolute;
    width: 0px;
    height: 0px;
    left: auto;
    right: -10px;
    top: auto;
    bottom: 0px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 10px solid rgb(182, 215, 237);
  }
}

.subscription-page {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.subscription-options {
  text-align: center;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .multi-product-card {
    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
    }

    .ant-btn {
      background-color: white !important;
      border-color: transparent !important;
      color: $darkGreen !important;

      &:hover {
        background-color: $lightGreen !important;
        border-color: transparent !important;
        color: white !important;
      }
    }

    .ant-radio-button-wrapper {
      font-weight: bold;
      height: 30px;
      padding: 0px 10px !important;
      width: fit-content !important;
    }
    .ant-radio-button-wrapper:first-child {
      border-radius: 20px 0px 0px 20px;
    }

    .ant-radio-button-wrapper:last-child {
      border-radius: 0 20px 20px 0;
    }

    .ant-radio-button-wrapper:first-child:last-child {
      border-radius: 20px;
    }

    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 15px lightgray;
    padding: 20px;
    margin: 10px;
    gap: 15px;
    border-radius: 30px;
    align-items: center;
    justify-content: space-between;
    background: rgb(206, 232, 202);
    color: black;
    width: 200px;
    margin: auto;
    height: 100%;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: $orange;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover::before {
    background-color: $orange;
  }

  .ant-radio-button-wrapper:hover {
    color: $orange;
  }

  &.disabled {
    div {
      pointer-events: none;
    }
    &:hover {
      cursor: not-allowed;
    }
    filter: grayscale(0.5);
    background: lightgray;
  }

  .header {
    font-weight: bold;
    font-size: 16px;
  }
}

.divider {
  position: relative;
  height: 1px;
  width: 100%;
  background-color: #eaeaea;
}

.feed-header {
  z-index: 10;
  background-color: rgba(82, 82, 82, 0.1);
  .content-container {
    height: 85px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h4 {
      flex: 1;
      font-size: 18px;
      font-weight: bold;
      color: #707070;
    }
  }
  .edit-group-btn {
    background-color: transparent;
    border-radius: 6px;
    padding: 6px 16px;
    font-weight: bold;
    color: $lightGreen;
    border: none;
    cursor: pointer;

    &.active {
      background-color: $lightGreen;
      color: white;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.description-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .pro-pic {
    z-index: 1;
    img {
      margin-right: -25px;
      width: 50px;
      height: 50px;
    }
  }

  .input-container {
    display: flex;
    flex-direction: row;
    flex: 1;

    .description-input-textarea {
      background-color: #f0f0f0;
      flex: 1;
      border: none !important;
      outline: none;
      box-shadow: none !important;
      padding: 10px 20px 10px 0px !important;
      max-height: 130px !important;
      overflow: auto;
    }
    .suffix {
      display: flex;
      flex-direction: row;

      .action-container {
        background-color: #f0f0f0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px;
      }
      .submit {
        padding: 6px 26px;
        background-color: $orange;
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
        border: none;
        cursor: pointer;
        height: 100% !important;

        span {
          color: white;
        }
      }
    }
  }
}

.no-style-btn {
  background-color: transparent !important;
  border: none !important;
  height: 30px !important;
  width: 30px !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;

  &:hover {
    background-color: #00000022 !important;
    border: none !important;
  }
}
.text-btn {
  background-color: transparent !important;
  border: none !important;
  display: flex;
  padding: 6px 0;
  cursor: pointer;
}

.picture-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.file-card {
  position: relative;
  width: 100px;
  height: 100px;
  justify-content: center;
  display: flex;
  align-items: center;

  .no-style-btn {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #4f8376 !important;
    border-radius: 999px;
    height: 24px !important;
    width: 24px !important;

    svg {
      color: #fff !important;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    //border-radius: 8px;
  }
}

.group-section-title {
  font-weight: 400;
  color: $lightGreen;
}

.groups-list {
  margin-bottom: 20px;
  max-height: 160px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.group-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  .group-icon {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 999px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background-color: #ff6060;
    }

    img {
      width: 18px;
      height: 18px;
      border-radius: 999px;
    }

    span {
      color: white;
      font-size: 10px;
    }
  }

  > span {
    font-size: 13px;
    color: #000;
    margin-left: 10px;
  }
}

.person-item {
  padding: 10px 6px !important;

  &.active {
    .ant-list-item-meta-content {
      h4 {
        color: $lightGreen !important;
        font-weight: bold !important;
      }
    }
  }

  .ant-list-item-meta-content {
    align-self: center;
    h4 {
      font-weight: normal !important;
    }
  }
}

.edit-group-container {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 400px;
  padding: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 2px 2px 8px lightgrey;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .section-container {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #eaeaea;

    span {
      color: $lightGreen;
      font-weight: bold;
      font-style: oblique;
      flex: 1;
    }

    h4 {
      display: flex;
      flex-direction: row;
    }
  }
}
.edit-search-container {
  position: absolute;
  margin-top: 556px;
  min-width: 300px;
  max-height: 530px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 1px 1px 4px rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 100;
}
.close {
  font-size: 26px;
  font-weight: 300;
  cursor: pointer;
}
.closediv {
  width: 0px;
}

.admin-badge {
  color: white;
  font-size: 11px;
  font-style: oblique;
  border-radius: 999px;
  padding: 4px 10px;
  background-color: $lightGreen;
  font-weight: bold;
}

.drawer-action-container {
  padding: 10px;
  border-top: 1px solid $lightGreen;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }
}

.post-action-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-left: 20px;

  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    &:active {
      color: #00000055;
    }
  }
}

.comment-creator-container {
  padding-left: 20px;
}

.comment-list-container {
  max-height: 300px;
  overflow: auto;
  text-align: center;
}

.post-container,
.comment-container {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  .main-content {
    //padding-left: 20px;
    padding: 0px 5px 0px 5px;
    //padding-left: 5px;
    margin: 0 0;
    text-align: left;
    p {
      margin: 0px;
    }
  }
}

.comment-container {
  padding: 10px 0 0 0;
  .main-content {
    padding-left: 50px;
  }
}

.author-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .main-header {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .group-badge {
    border-radius: 999px;
    padding: 6px 10px;
    background-color: $orange;

    span {
      color: white;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .propic {
    margin-right: 10px;
  }

  .author-meta-container {
    display: flex;
    align-items: center;

    .username {
      font-size: 12px;
      color: #4f8376;
      margin-right: 10px;
    }
    .connection-type {
      font-size: 10px;
      color: #4f8376;
    }
  }
}

.person-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.parents-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.grid-layout-tree.bottom-child {
  position: absolute !important;
  top: 315px !important;
  right: 70px !important;
  width: auto;
}
.grandfather-layout {
  margin-left: 40px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.secondparent-layout {
  margin-left: 50px;
  margin-top: 70px;
}
.new-class {
  direction: ltr;
  width: 170px;
  height: 50px;
  margin: 6px;
  border-radius: 30px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 15px;
  border: 1px solid $lightGreen;
  color: $lightGreen;
  background-color: white;
  font-size: 12px;

  font-style: italic;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.hover-space {
  padding: 5px;
  display: flex;
  flex-direction: column;

  .btn-row {
    border-bottom: 1px solid $lightGreen;
    display: flex;
    justify-content: space-around;
    padding-bottom: 5px;

    .btn {
      border-radius: 30px;
      width: 30px;
      padding: 4px;
      border: 1px solid $lightGreen;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: $lightGreen;
        color: white;
      }
    }
  }
  .edit-row {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
.ancestors-popover {
  &.hide {
    display: none;
  }
  .ant-popover-inner {
    border-radius: 20px;
  }
}

.Popover-class {
  background-color: red;
}
.inner_layout {
  position: fixed;
}
.item {
  align-items: flex-end;
}

.arrow {
  position: absolute;
  border-radius: 3px;
  font-size: 26px;
  top: 50%;
}

.left_arrow {
  display: flex;
  flex-direction: row;
}

.right_arrow {
  display: flex;
  flex-direction: row;
}

.wrap_columns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.DownOutlined {
  font-size: x-large;
  opacity: 1;
  // background-color: #81398833;
  border-radius: 50%;
}

.Upward_Button {
  margin-top: 100px;
  font-size: x-large;
  opacity: 1;
  border-radius: 50%;
  // background-color: #81398833;
}

.folder_height {
  height: 700px !important;
}
.folder_height1 {
  // display: grid;
  height: 200px !important;
}
.scroll_class {
  overflow-y: scroll;
  overflow-x: hidden;
}

.icon_handle {
  position: relative;
  margin-left: 50px;
  color: white;
  font-size: x-large;
  padding-top: 35px;
}

// .hidden {
//   display: none;
// }
.React_Player {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 580px;
  background: #4f8376 0% 0% no-repeat padding-box;
  opacity: 1;
}
.Video_hading {
  text-align: center;
  font: normal normal bold 24px/24px Segoe UI;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  padding: 15px;
}

.video_size {
  padding: 20px;
  border: 5px solid #ffffff;
  border-radius: 20px;
  width: fit-content;
}
.border-video {
  border-radius: 20px;
}

.ant-carousel {
  .slick-next {
    &::before {
      content: "";
    }
  }
  .slick-prev {
    &::before {
      content: "";
    }
  }
}
.ant-carousel .slick-dots {
  visibility: hidden;
}
