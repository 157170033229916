$darkGreen: #1f342f;
$darkGreenAlpha: #1f342fbf;
$lightGreen: #4f8376;
$lightGreenAlpha: rgba(93, 185, 162, 0.3);
$neonGreen: #1fc74c;
$limeGreen: #cee8ca;
$cyan: #03bac9;
$darkCyan: #31bac6;
$lightCyan: #91d5ff;
$peach: #f3a989;
$orangeAlpha: rgba(230, 77, 14, 0.7);
$pink: #ffadd2;
$pea: #d3f261;
$yellow: #ffd591;

$blue: #038abf;
$lightOrange: #d95409;
$orange: #e64d0e;
$darkorange: #e22b04;
$red: #d90516;
$purple: #8c328c;
$purpleAlpha: #81398833;

$blueDark: #056c95;
$redDark: #ac000e;
$purpleDark: #5d205d;

:export {
  blue: $blue;
  red: $red;
  purple: $purple;
  blueDark: $blueDark;
  redDark: $redDark;
  purpleDark: $purpleDark;
  cyan: $cyan;
  peach: $peach;
  darkOrange: $darkorange;
  lightCyan: $lightCyan;
  darkCyan: $darkCyan;
  orange: $orange;
  lightOrange: $lightOrange;
  lightGreen: $lightGreen;
  limeGreen: $limeGreen;
  darkGreen: $darkGreen;
  darkGreenAlpha: $darkGreenAlpha;
  lightGreenAlpha: $lightGreenAlpha;
  orangeAlpha: $orangeAlpha;
  neonGreen: $neonGreen;
  pink: $pink;
  pea: $pea;
  yellow: $yellow;
}
